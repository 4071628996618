const axios = require("./Axios");

export const Consulta = function (arg) {
  return new Promise(function (resolve, reject) {
      axios.get(`/personas/list?nombre=${arg.nombre}`)
      .then(results => {
        if(results.data.result === 'ok') {
          var data = JSON.stringify(results.data);
          resolve(data);
        } else {
          reject(null);
        }

      }).catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          reject(null);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
          reject(null);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
          reject(null);
        }
    
      });

      
  });

  
};

export const Guardar = function (arg) {
  return new Promise(function (resolve, reject) {
      axios.post('/personas/guardar', {
        id    : arg.id,
        cedula : arg.cedula,
        nombre : arg.nombre,
        fecha_nacimiento : arg.fecha_nacimiento,
        fecha_ingreso : arg.fecha_ingreso,
        direccion    : arg.direccion,
        telefono : arg.telefono,
        is_bautizado : arg.is_bautizado,
        is_servidor : arg.is_servidor,
        territorio_id : arg.territorio_id,
        primer_evento_id : arg.primer_evento_id,
        peticion : arg.peticion,
        sexo : arg.sexo,
      })
      .then(results => {
        var data = JSON.stringify(results.data);
        //console.log(data);
        if(results.data.result === 'ok') {
          resolve(data);
        } else {
          reject(null);
        }

      }).catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          reject(null);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
          reject(null);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
          reject(null);
        }
    
      });
  });
  
};


export const Obtener = function (id,list) {
  return new Promise(function (resolve, reject) {
      axios.get(`/personas/obtener?id=${id}&${list}=0`)
      .then(results => {
        if(results.data.result === 'ok') {
          var data = JSON.stringify(results.data);
          resolve(data);
        } else {
          reject(null);
        }
      }).catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          reject(null);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
          reject(null);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
          reject(null);
        }
      });
  });
};

export const Cne = function (nac, cedula) {
  return new Promise(function (resolve, reject) {
      axios.get(`/users/cne?nac=${nac}&cedula=${cedula}`)
      .then(results => {
        var data = JSON.stringify(results.data);
        if(results.data.result === 'ok') {
          resolve(data);
        } else {
          resolve(data);
        }
      }).catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          reject(null);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
          reject(null);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
          reject(null);
        }
      });
  });
};