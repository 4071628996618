import React, { useState } from "react";
import { useParams } from "react-router-dom";
//import ConsultaForm from "../../components/Evento/ConsultaForm";
//import GuardarForm from "../../components/Evento/GuardarForm";
import NuevasAlmas from "../../components/Consulta/NuevasAlmas";
import "./Consulta.scss";

export default function Evento(props) {
  let params = useParams();
  const { setUser, accion } = props;
  const [selectedForm, setSelectedForm] = useState(accion);

  const handlerForm = () => {
    switch (accion) {
      case "nuevas-almas":
        return <NuevasAlmas setSelectedForm={setSelectedForm} setUser={setUser} />
      default:
        return <NuevasAlmas setSelectedForm={setSelectedForm} setUser={setUser} />
    }
  };

  
  return (
      <>
        {handlerForm()}
      </>
  );
}