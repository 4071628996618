import React, { useState, useEffect } from "react";
import { Button, Form, Input } from "semantic-ui-react";
import { toast } from "react-toastify";
import "./GuardarForm.scss";
import { useNavigate } from "react-router-dom";
//import { DateTimeInput } from "semantic-ui-calendar-react";
import DatePicker from 'react-datepicker';

import "react-datepicker/dist/react-datepicker.css";

import Moment from 'moment';

const Eventos = require("../../../models/Eventos");



export default function GuardarForm(props) {


  const { eventoId } = props;
  const navegar = useNavigate();

  const [formData, setFormData] = useState(defaultValueForm());
  const [formError, setFormError] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  

  
  const onChange = (e, {name, value}) => {
    //console.log(`Name: ${name}  - Value: ${value}`);
    //const myMomentObject = Moment(value, 'DD-MM-YYYY HH:mm');
    //console.log(`Fecha: ${myMomentObject.toISOString()}`);
    if(name !== 'fecha') setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    if(name === 'fecha') setFormData({
      ...formData,
      [name]: value,
    });
    
  };
  
  
  


  const onSubmit = () => {
    setFormError({});
    let errors = {};
    let formOk = true;

    //console.log(validateEmail(formData.email));


    if (!formData.descripcion) {
      errors.descripcion = true;
      formOk = false;
    }

    setFormError(errors);

    if (formOk) {
      setIsLoading(true);
      Eventos.Guardar(formData)
        .then(function (data) {
          setIsLoading(false);
          var res = JSON.parse(data);
          if (res.result === 'ok') {
            toast.success("Evento Guardado Exitosamente");
            navegar('/evento-consulta');
          } else {
            toast.error(res.msg);
          }
        })
        .catch(function (error) {
            setIsLoading(false);
            toast.error("Ocurrio un Problema a Registrar");
          console.log(error);
        });
    }
  };


  useEffect(() => {
    let eId = parseInt(eventoId, 10)
    if(eId > 0) {
      setIsLoading(true);
      Eventos.Obtener(eId,0)
      .then(function (data) {
        setIsLoading(false);
        let dt = JSON.parse(data)
        if(dt.result==='ok') {
          console.log(dt.data.fecha);
          dt.data.fecha = Moment.utc(dt.data.fecha, 'YYYY-MM-DD HH:mm').toDate();
          setFormData(dt.data);
        }
      })
      .catch(function (error) {
        setIsLoading(false);
        toast.error("Ocurrio un Problema");
        console.log(error);
      });
    } else {
      setFormData(defaultValueForm());
    }
  }, [ eventoId ] );

  /*
  <DateTimeInput
            name="fecha"
            placeholder="Date Time"
            value={Moment(formData.fecha).format('DD/MM/YYYY hh:mm a')}
            iconPosition="right"
            onChange={onChange}        
            disabled={isLoading}
          />
          */

  const handlerForm = () => {
    return (
      <div className="register-form">
        <h1>Datos del Evento</h1>
        <Form onSubmit={onSubmit}  loading={isLoading}>
          <Form.Field hidden>
            <Input
              type="text"
              name="id"
              placeholder="Id"
              icon="mail outline"
              value={formData.id}
            />
          </Form.Field>
          <Form.Field>
          <span className="title-text">Fecha</span>
          
          
          <DatePicker
            name="fecha"
            showTimeSelect
            dateFormat="dd/MM/yyyy HH:mm"
            onChange={(date) => onChange(this, {name:'fecha', value:date})}
            selected={formData.fecha}
          />
          
          {formError.fecha && (
              <span className="error-text">Fecha Invalida</span>
          )}
          </Form.Field>
          <Form.Field>
            <span className="title-text">Descripcion</span>
            <Input
              type="text"
              name="descripcion"
              placeholder="Descripcion"
              icon="user circle outline"
              value={formData.descripcion}
              error={formError.descripcion}
              onChange={onChange}
              disabled={isLoading}
            />
            {formError.descripcion && (
              <span className="error-text">Debe Ingresar una Descripcion</span>
            )}
          </Form.Field>
          <Button type="submit" name="submit" loading={isLoading}>
            Guardar
          </Button>
        </Form>
      </div>
    )
  }



  return (
    <>
      {handlerForm()}
    </>
  );
}

function defaultValueForm() {
  return {
    id: 0,
    fecha: new Date(),
    descripcion: "",
  };
}
