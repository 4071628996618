import React from "react";
import { Label, Search } from "semantic-ui-react";
import { toast } from "react-toastify";
import "./BuscarPersona.scss";


const Personas = require("../../../models/Personas");



export default function BuscarPersona(props) {


  const { setNewAsist } = props;
  
    
  const busqIni = {
    loading: false,
    results: [],
    value: '',
  }

  function exampleReducer(state, action) {
    switch (action.type) {
      case 'CLEAN_QUERY':
        return busqIni
      case 'START_SEARCH':
        return { ...state, loading: true, value: action.query }
      case 'FINISH_SEARCH':
        return { ...state, loading: false, results: action.results }
      case 'UPDATE_SELECTION':
        return { ...state, value: action.selection }
      default:
        throw new Error()
    }
  }

  

  const [state, dispatch] = React.useReducer(exampleReducer, busqIni)

  const timeoutRef = React.useRef()

  const { loading, results, value } = state


  const handleSeleccion = React.useCallback((e, data) => {
    
      setNewAsist(data.result.id)
      //dispatch({ type: 'UPDATE_SELECTION', selection: data.result.nombre })
      dispatch({ type: 'CLEAN_QUERY' })
    
  })

  const handleSearchChange = React.useCallback((e, data) => {
    
    
    clearTimeout(timeoutRef.current)
    dispatch({ type: 'START_SEARCH', query: data.value })

    

    timeoutRef.current = setTimeout(() => {
      if (data.value.length === 0) {
        
      } else {
        let xarg = { nombre: data.value}
        Personas.Consulta(xarg)
        .then(function (data) {
          let dt = JSON.parse(data)
          if(dt.result==='ok') {
            dispatch({
              type: 'FINISH_SEARCH',
              results: dt.data,
            })
            return
          } 
          
        })
        .catch(function (error) {
          toast.error("Ocurrio un Problema");
          console.log(error);
        });
      }

      
      

      
    }, 300)
  }, [])


  const resultRenderer = ({ cedula, nombre }) => <Label content={`${cedula} - ${nombre.toUpperCase()}`} />

/*
  useEffect(() => {
  
    setFormData(defaultValueForm());
    
  }, [ eventoId ] );

*/
  
  return (
    <>
            <Search
              fluid
              input={{ fluid: true }}
              loading={loading}
              placeholder="Agregar por Cedula ó Nombre.."
              onSearchChange={handleSearchChange}
              onResultSelect={handleSeleccion}
              results={results}
              value={value}
              resultRenderer={resultRenderer}
              
            />
      </>
  );
}


