import React, { useState } from "react";
import { Button, Icon, Form, Input } from "semantic-ui-react";
import { toast } from "react-toastify";
import { validateEmail } from "../../../utils/Validations";
import "./RegisterForm.scss";
import { useNavigate } from "react-router-dom";

const Usuarios = require("../../../models/Usuarios");

export default function RegisterForm(props) {
  //const { setSelectedForm } = props;
  const navegar = useNavigate();

  const [formData, setFormData] = useState(defaultValueForm());
  const [showPass, setShowPass] = useState(false);
  const [formError, setFormError] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handlerShowPass = () => {
    setShowPass(!showPass);
  };

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = () => {
    setFormError({});
    let errors = {};
    let formOk = true;

    //console.log(validateEmail(formData.email));

    if (!validateEmail(formData.email)) {
      errors.email = true;
      formOk = false;
    }

    if (formData.password.length < 6) {
      errors.password = true;
      formOk = false;
    }

    if (!formData.nombre) {
      errors.nombre = true;
      formOk = false;
    }

    setFormError(errors);

    if (formOk) {
      setIsLoading(true);
      Usuarios.Register(formData)
        .then(function (data) {
          var res = JSON.parse(data);
          //console.log(res);
          if (res.result === 'ok') {
            toast.success("Usuario Registrado Exitosamente");
            navegar('/');
          } else {
            toast.error(res.msg);
          }
          setIsLoading(false);
        })
        .catch(function (error) {
          toast.error("Ocurrio un Problema a Registrar");
          console.log(error);
          setIsLoading(false);
        });
    }
  };

  return (
    <div className="register-form">
      <h1>Datos del Usuario</h1>
      <Form onSubmit={onSubmit} onChange={onChange}>
        <Form.Field>
          <Input
            type="text"
            name="email"
            placeholder="Correo electronico"
            icon="mail outline"
            //onChange={}
            error={formError.email}
          />
          {formError.email && (
            <span className="error-text">Email invalido</span>
          )}
        </Form.Field>
        <Form.Field>
          <Input
            type={showPass ? "text" : "password"}
            name="password"
            placeholder="Contraseña"
            icon={
              showPass ? (
                <Icon name="eye slash outline" link onClick={handlerShowPass} />
              ) : (
                <Icon name="eye" link onClick={handlerShowPass} />
              )
            }
            //onChange={}
            error={formError.password}
          />
          {formError.password && (
            <span className="error-text">
              La clave debe tener al menos 6 digitos
            </span>
          )}
        </Form.Field>
        <Form.Field>
          <Input
            type="text"
            name="cedula"
            placeholder="Cedula de Identidad"
            icon="user circle outline"
            //onChange={}
            error={formError.cedula}
          />
          {formError.cedula && (
            <span className="error-text">Debe Ingresar su Numero de Cedula</span>
          )}
        </Form.Field>
        <Form.Field>
          <Input
            type="text"
            name="nombre"
            placeholder="Nombre completo"
            icon="user circle outline"
            //onChange={}
            error={formError.nombre}
          />
          {formError.nombre && (
            <span className="error-text">Debe Ingresar su Nombre Completo</span>
          )}
        </Form.Field>
        <Button type="submit" name="submit" loading={isLoading}>
          Continuar
        </Button>
      </Form>
    </div>
  );
}

function defaultValueForm() {
  return {
    email: "",
    password: "",
    cedula: "",
    nombre: "",
  };
}
