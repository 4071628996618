import React, { useState, useEffect } from "react";
import { Table, Label, Segment, Form, Button, Menu, Placeholder, Grid, Icon } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DatePicker from 'react-datepicker';
import Moment from 'moment';
import { Chart } from "react-google-charts";

const Consultas = require("../../../models/Consultas");
var charTipo = 'ColumnChart';

var imagenBASE64 = null;

var charOptions = {
  title: "Nuevas Almas Mensuales",
  imgId: 1,
  vAxis: { title: "Cantidad" },
  hAxis: { title: "Fecha", 
          textStyle: {
              color: 'black',
            },
          slantedText: true, 
          slantedTextAngle: 45},
  annotations: {
    textStyle: {
      color: 'black',
    }
  },
  theme: 'material',
  legend: 'top',
  isStacked: true,
  chartArea: {
    bottom: 120
  },
}
export default function NuevasAlmas(props) {
  //const { setSelectedForm } = props;
  const navegar = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [optChartAct, setOptChartAct] = useState('mensual');
  const [formData, setFormData ] = useState(defaultValueForm());
  const [chartLoading, setChartLoading] = useState(false);
  const [lstAlmas, setLstAlmas] = useState([]);
  const [dataChart, setDataChart] = useState([]);
  const [imgUrl, setImgUrl] = useState(null);
  const [filtro, setFiltro] = useState(0);
  var dataBusq = defaultValueForm();
  

  const mesDesc = (txtfec) => {
    var mes = txtfec.split('-')[1];
    var ano = txtfec.split('-')[0].substring(2,4);
    //console.log(mes)
    if(mes === '01') mes = 'Enero'
    if(mes === '02') mes = 'Febrero'
    if(mes === '03') mes = 'Marzo'
    if(mes === '04') mes = 'Abril'
    if(mes === '05') mes = 'Mayo'
    if(mes === '06') mes = 'Junio'
    if(mes === '07') mes = 'Julio'
    if(mes === '08') mes = 'Agosto'
    if(mes === '09') mes = 'Septiembre'
    if(mes === '10') mes = 'Octubre'
    if(mes === '11') mes = 'Noviembre'
    if(mes === '12') mes = 'Diciembre'

    

    return mes + " " + ano
  }


  var cargarChartMensual = () => {
    setDataChart([])
    charTipo = 'ColumnChart'
    charOptions.title = "Grafico de Nuevas Almas Mensuales"
    charOptions.hAxis.slantedText = true;
    charOptions.hAxis.title = 'Fecha'
    charOptions.colors = ['#96defa','#f5bcf7']
    setChartLoading(true);
    Consultas.NuevasAlmas(Moment.utc(formData.fecha_ini).format('YYYY-MM-DD'),Moment.utc(formData.fecha_fin).format('YYYY-MM-DD'))
    .then(function (data) {
      //console.log('Data Eventos: ' + data);
      var dt = JSON.parse(data);
      setLstAlmas(dt.data.rows);
      var chartDt = [];
      chartDt.push(['Fecha', 'Masculino',{ role: 'annotation' },  'Femenino', { role: 'annotation' } ])
      dt.data.rows.map(function(item) {
        //,`Tot: ${item.cant}`
        chartDt.push([mesDesc(item.mes),Number.parseInt(item.mas),`${item.mas}` ,parseInt(item.fem),`${item.fem}`])
      });
      setDataChart(chartDt)

    })
    .catch(function (error) {
      setIsLoading(false);
      toast.error("Ocurrio un Problema");
      console.log(error);
    });
  }

  var cargarChartTerritorios = () => {
    setDataChart([])
    charTipo = 'BarChart'
    charOptions.title = `Grafico de Nuevas Almas por Territorio desde el ${Moment.utc(formData.fecha_ini).format('DD/MM/YYYY')} hasta ${Moment.utc(formData.fecha_fin).format('DD/MM/YYYY')}`
    charOptions.hAxis.slantedText = false;
    charOptions.vAxis.title = 'Territorio'
    charOptions.hAxis.title = 'Cantidad'
    charOptions.colors = ['#9fbafc','#faa7c6']
    //charOptions.isStacked = false;
    setChartLoading(true);
    Consultas.NuevasAlmasTerritorios(Moment.utc(formData.fecha_ini).format('YYYY-MM-DD'),Moment.utc(formData.fecha_fin).format('YYYY-MM-DD'))
    .then(function (data) {
      //console.log('Data Eventos: ' + data);
      var dt = JSON.parse(data);
      //setLstAlmas(dt.data.rows);
      var chartDt = [];
      chartDt.push(['Territorio', 'Masculino', { role: 'annotation' }, 'Femenino', { role: 'annotation' } ])
      dt.data.rows.map(function(item) {
        var ter = item.terri;
        ter = ter.replace('TERRITORIO ','');
        ter = ter.replace('NO ASIGNADO','S/T');
        chartDt.push([ter,Number.parseInt(item.mas),`${item.mas}`, parseInt(item.fem),`${item.fem}`])
      });
      setDataChart(chartDt)

    })
    .catch(function (error) {
      setIsLoading(false);
      toast.error("Ocurrio un Problema");
      console.log(error);
    });
  }

  var cargarChartRedes = () => {
    setDataChart([])
    charTipo = 'TreeMap'
    charOptions.title = `Grafico de Nuevas Almas por Red desde el ${Moment.utc(formData.fecha_ini).format('DD/MM/YYYY')} hasta ${Moment.utc(formData.fecha_fin).format('DD/MM/YYYY')}`
    charOptions.maxDepth = 1
    charOptions.maxPostDepth = 2
    setChartLoading(true);
    Consultas.NuevasAlmasTerritorios(Moment.utc(formData.fecha_ini).format('YYYY-MM-DD'),Moment.utc(formData.fecha_fin).format('YYYY-MM-DD'))
    .then(function (data) {
      //console.log('Data Eventos: ' + data);
      var dt = JSON.parse(data);
      //setLstAlmas(dt.data.rows);
      var chartDt = [];
      chartDt.push(['Ubicacion', 'Parent', 'Masculimo','Femenino' ])
      chartDt.push(['Cuadrangular Sion',null,0,0])
      chartDt.push(['Red 1','Cuadrangular Sion',0,0])
      chartDt.push(['Red 2','Cuadrangular Sion',0,0])
      let ter=0;
      let padre=''
      var n = 0;
      dt.data.rows.map(function(item) {
        var ter = item.terri;
        ter = ter.replace('TERRITORIO ','');
        ter = ter.replace('NO ASIGNADO','S/T');
        var xter = ''
        var nter = ''
        if(ter === 'S/T')  {
          nter=0
          xter='Sin Red Asignada'
        } else {
          nter = parseInt(ter)
          xter = `Territorio ${ter}`
        }
        if(nter == 0) {
          padre='Cuadrangular Sion'
        } else if (ter >= 5) {
          padre='Red 2'
        } else {
          padre='Red 1'
        }
        chartDt.push([`${xter}`,`${padre}`,Number.parseInt(item.cant), n++])
      });
      setDataChart(chartDt)

    })
    .catch(function (error) {
      setIsLoading(false);
      toast.error("Ocurrio un Problema");
      console.log(error);
    });
  }

  useEffect(() => {
    setIsLoading(false);
    handleItemClick('mensual')
    //cargarChartMensual();
  }, [ filtro ] );

  const copiaImagen = () => {
    if(imagenBASE64 == null) return;
    try {

        navigator.clipboard.write([
          // eslint-disable-next-line no-undef
          new ClipboardItem({[imagenBASE64.type]: imagenBASE64})
          //new ClipboardItem({'image/png': pngBlob})
        ]);
        toast.success("Imagen Copiada al Portapapeles");
        //console.log("Image copied");
      } catch (error) {
        //console.error(error);
      }
  }

  const BotonCopiar = (props) => {
    return (<Button color='blue' disabled={props.disabled} onClick={() => copiaImagen()}icon labelPosition='left'>
    <Icon name='copy' />Copiar</Button>)
  }

  const onSubmit = () => {
      setFiltro(e => e + 1);
  }


  const onChange = (e, {name, value}) => {
    if(name === 'fecha_ini' || name === 'fecha_fin') setFormData({
      ...formData,
      [name]: value,
    });
  };

  
  const Grafico = (props) => {
    if(props.dataCh.length == 0) return (
    <><Placeholder style={{ height: 500, width: 900 }}>
      <Placeholder.Image />
    </Placeholder></>)

    return (<Chart
      chartType={props.chartTipo}
      data={props.dataCh}
      options={charOptions}
      width="900px"
      height="500px"
      
      chartEvents={[
        {
          eventName: "ready",
          callback: ({chartWrapper}) => {
            //  console.log(`ImgId: ${chartWrapper.getChart().options.imgId}`);
            var chart
            if(chartWrapper.getChartType() === 'ColumnChart' || chartWrapper.getChartType() === 'BarChart') {
              chart = chartWrapper.getChart().getImageURI()
            }
            
            fetch(chart).then(r=>r.blob()).then(blob => {
                imagenBASE64 = blob;
            })
            setChartLoading(false);            
          }
        }
      ]}
    />)
  }

  const handleItemClick = (name) => {

    setOptChartAct(name)
    //console.log(`Nombre: ${name}`)
    if(name === 'mensual') {
      cargarChartMensual();
    }

    if(name === 'territorios') {
      cargarChartTerritorios();
    }

    if(name === 'redes') {
      cargarChartRedes();
    }

  }

  const OptChart = (props) => {
    
    return (<Menu tabular>
      <Menu.Item
        name='mensual'
        active={props.act === 'mensual'}
        onClick={() => handleItemClick('mensual')}
      />
      <Menu.Item
        name='territorios'
        active={props.act === 'territorios'}
        onClick={() => handleItemClick('territorios')}
      />
      <Menu.Item
        name='redes'
        active={props.act === 'redes'}
        onClick={() => handleItemClick('redes')}
      />
      </Menu>)    
  }

  const SelectRangoFecha = (props) => {
    return (<Form onSubmit={onSubmit} >
          <Form.Group widths='equal' >
          <Form.Field>
          <Label className="" color='black' >Fecha Inicial</Label>
          <DatePicker
            name="fecha_ini"
            showTimeSelect
            dateFormat="dd/MM/yyyy"
            onChange={(date) => onChange(this, {name:'fecha_ini', value:date})}
            selected={formData.fecha_ini}
          />
          </Form.Field>
          <Form.Field>
          <Label className="" color='black' >Fecha Final</Label>
          <DatePicker
            name="fecha_fin"
            showTimeSelect
            dateFormat="dd/MM/yyyy"
            onChange={(date) => onChange(this, {name:'fecha_fin', value:date})}
            selected={formData.fecha_fin}
          />
          </Form.Field>
          <Button type="submit" name="submit" loading={isLoading}>
            Buscar
          </Button>
          </Form.Group>
          
        </Form>)
  }

  return (
    <>
    <Segment loading={isLoading}>
      <Label attached='top' size='huge'>Nuevas Almas</Label>
      <Segment>
        <SelectRangoFecha />
      </Segment>
      <Segment>
        <Grid>
          <Grid.Row>
            <Grid.Column width={13}>
              <OptChart act={optChartAct} />
            </Grid.Column>
            <Grid.Column width={3}>
              <BotonCopiar disabled={chartLoading}/>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Segment loading={chartLoading}>
          <Grafico dataCh={dataChart} chartTipo={charTipo}/>
        </Segment>
      </Segment>
      
    </Segment>
    
    </>
  );
}

function defaultValueForm() {
  return {
    fecha_ini: Moment.utc(new Date()).subtract(5, 'months').toDate(),
    fecha_fin: new Date(),
  };
}
/*
<Table >
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width="5" >Fecha</Table.HeaderCell>
          <Table.HeaderCell width="5">Cantidad</Table.HeaderCell>
          <Table.HeaderCell width="10" >Accion</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
      {lstAlmas.map(almas => (
          <Table.Row key={almas.mes}>
            <Table.Cell>{mesDesc(almas.mes)}</Table.Cell>
            <Table.Cell>{almas.cant}</Table.Cell>
            <Table.Cell >
              <Button  onClick={() => {navegar(`/evento-editar/${almas.mes}`)}} circular icon='edit' />
              <Button  onClick={() => {navegar(`/evento-control/${almas.mes}`)}} circular icon='list ol' />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>

      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan='3'>
            
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
      </Table>
      */