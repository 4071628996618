import React, { useState, useEffect } from "react";
import { Button, Form, Input, Label, TextArea, Select } from "semantic-ui-react";
import { toast } from "react-toastify";
import "./GuardarPersona.scss";
//import { DateInput } from "semantic-ui-calendar-react";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'moment';

const Eventos = require("../../../models/Eventos");
const Personas = require("../../../models/Personas");
const Territorios = require("../../../models/Territorios");

const bautiOpciones = [
  { key: '0', text: 'No', value: '0' },
  { key: '1', text: 'Si', value: '1' },
]

const sexoOpciones = [
  { key: 'F', text: 'FEMENINO', value: 'F' },
  { key: 'M', text: 'MASCULINO', value: 'M' },
]

const terriDefault = [
  { key: 0, text: 'NO ASIGNADO', value: 0 },
]

export default function GuardarPersona(props) {

  const { personaId, setOpen, eventoId, setEvNum, evNum } = props;

  const [formData, setFormData] = useState(defaultValueForm());
  const [terriData, setTerriData] = useState(terriDefault);
  const [comboLoading, setComboLoading] = useState(false);
  const [isBusqCne, setIsBusqCne ] = useState(false);
  const [formError, setFormError] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const onChange = (e, {name, value}) => {

    if (typeof value === 'string' || value instanceof String) {
      value = value.toUpperCase();
    }
    

    setFormData({
      ...formData,
      [name]: value,
    });

    /*
    if(name === 'fecha_ingreso' || name === 'fecha_nacimiento') setFormData({
      ...formData,
      [name]: Moment(value, 'DD-MM-YYYY').toISOString(),
    });
    */
  };
  
  const onBusqCNE = (value) => {
    setIsBusqCne(true)
    
    if(parseInt(value) > 0) {
      Personas.Cne('V', value).then(function (data) {
        setIsBusqCne(false)
        let dt = JSON.parse(data)
        if(dt.result === 'ok') {
          setFormData({
            ...formData,
            nombre: dt.data.nombre,
            direccion: `${dt.data.direccion}\n ${dt.data.estado}, ${dt.data.municipio}, ${dt.data.parroquia}`,
          });

          //toast.success("Cedula encontrada");
        } else {
          toast.error(dt.data);
        }
      }).catch(function (error) {
        setIsBusqCne(false)
        toast.error("Ocurrio un Problema al Buscar en CNE");
        console.log(error);
      });
    }
    
  }
  


  const onSubmit = () => {
    setFormError({});
    let errors = {};
    let formOk = true;

    //console.log(validateEmail(formData.email));


    if (!formData.nombre) {
      errors.nombre = true;
      formOk = false;
    }

    setFormError(errors);

    if (formOk) {
      setIsLoading(true);
      Personas.Guardar(formData)
        .then(function (data) {
          setIsLoading(false);
          var res = JSON.parse(data);
          if (res.result === 'ok') {
            
            if(eventoId > 0 && personaId===0) {
              let personaId = res.data.id;
              let gaarg = { persona_id: personaId, evento_id: eventoId}
              Eventos.GuardarAsistencia(gaarg).then(function (data) {
                  setEvNum(evNum + 1);
              }).catch(function (error) {
                toast.error("Ocurrio un Problema a Registrar Asistencia");
                console.log(error);
              });
            }

            toast.success("Persona Guardada Exitosamente");
            if(setOpen != null) setOpen(false);
          } else {
            toast.error(res.msg);
          }
        })
        .catch(function (error) {
            setIsLoading(false);
            toast.error("Ocurrio un Problema a Registrar");
            if(setOpen != null) setOpen(false);
            console.log(error);
        });
    }
  };


  useEffect(() => {
    setComboLoading(true);
    
    

    Territorios.Consulta()
    .then(function (data) {
      setComboLoading(false);
      let dt = JSON.parse(data)
      if(dt.result==='ok') {
        
        terriDefault.length = 0;
        let value = 0;
        let name ='territorio_id';

        let peve = 'primer_evento_id';
        let peval = eventoId;


        for(let n=0;n<dt.data.length;n++) {
          let t = { key: dt.data[n].id, text: dt.data[n].descripcion, value: dt.data[n].id }
          if(n===dt.data.length) {
            value= dt.data[n].id;
          } 
          terriDefault.push(t);
        }
        setTerriData(terriDefault);

        setFormData({
          ...formData,
          [name]: value,        });

        setFormData({
          ...formData,
          [peve]: peval,
        });

      }
    })
    .catch(function (error) {
      setComboLoading(false);
      toast.error("No se Pudieron Cargar los Territorios");
      console.log(error);
    });

    console.log(`Persona: ${personaId}`)
    let pId = parseInt(personaId, 10)
    if(pId > 0) {
      //console.log(`Persona: ${pId}`)
      setIsLoading(true);
      Personas.Obtener(pId)
      .then(function (data) {
        setIsLoading(false);
        let dt = JSON.parse(data)
        dt.data.fecha_ingreso = Moment.utc(dt.data.fecha_ingreso, 'YYYY-MM-DD').toDate();
        dt.data.fecha_nacimiento = Moment.utc(dt.data.fecha_nacimiento, 'YYYY-MM-DD').toDate();
        if(dt.result==='ok') {
          setFormData(dt.data);
          //console.log(dt.data);
        }
      })
      .catch(function (error) {
        setIsLoading(false);
        toast.error("Ocurrio un Problema");
        console.log(error);
      });
    } else {
      setFormData(defaultValueForm());
    }
  }, [ personaId, eventoId ] );


  
  const handlerForm = () => {
    return (
    <>
        <Form onSubmit={onSubmit} loading={comboLoading} autoComplete="off">
          <Form.Field hidden>
            <Input
              type="text"
              name="id"
              placeholder="Id"
              icon="mail outline"
              value={formData.id}
            />
          </Form.Field>
          <Form.Field hidden>
            <Input
              type="text"
              name="primer_evento_id"
              placeholder="Id"
              icon="mail outline"
              value={formData.primer_evento_id}
            />
          </Form.Field>
          <Form.Group widths='equal'>
          <Form.Field >
          <Label className="" color='black'>Fecha de Ingreso</Label>
          <DatePicker
            name="fecha_ingreso"
            dateFormat="dd/MM/yyyy"
            onChange={(date) => onChange(this, {name:'fecha_ingreso', value:date})}
            selected={formData.fecha_ingreso}
          />
          {formError.fecha_ingreso && (
              <span className="error-text">Fecha Invalida</span>
          )}
          </Form.Field>
          <Form.Field  >
            <Label className="" color='black'>Cedula</Label>
            <Input
              action={{
                color: 'teal',
                labelPosition: 'right',
                icon: 'search',
                content: 'C.N.E',
                name: "cne",
                type: 'button',
                loading: isBusqCne,
                onClick: (event,data) => { onBusqCNE(formData.cedula); }
                
              }}
              type="text"
              name="cedula"
              placeholder="Cedula Ej: 11222333"
              icon="user circle outline"
              value={formData.cedula}
              error={formError.cedula}
              onChange={onChange}
              color='black'

            />
            {formError.cedula && (
              <span className="error-text">Numero de Cedula Invalido</span>
            )}
          </Form.Field>
          
          </Form.Group>
          <Form.Field >
            <Label className="" color='black' >Nombre Completo</Label>
            <Input
              type="text"
              name="nombre"
              placeholder="Nombre"
              icon="user circle outline"
              value={formData.nombre}
              error={formError.nombre}
              onChange={onChange}
            />
            {formError.nombre && (
              <span className="error-text">Debe Ingresar el Nombre</span>
            )}
          </Form.Field>
          <Form.Group widths='equal'>
          <Form.Field >
            <Label className=""  color='black'>Sexo</Label>
            <Select
              name="sexo"
              options={sexoOpciones}
              value={formData.sexo}
              error={formError.sexo}
              onChange={onChange}
              
            />
            {formError.sexo && (
              <span className="error-text">Erro de Dato</span>
            )}
          </Form.Field>
          <Form.Field >
          <Label className=""  color='black'>Fecha de Nacimiento</Label>
          <DatePicker
            name="fecha_nacimiento"
            dateFormat="dd/MM/yyyy"
            onChange={(date) => onChange(this, {name:'fecha_nacimiento', value:date})}
            selected={formData.fecha_nacimiento}
          />

          {formError.fecha_nacimiento && (
              <span className="error-text">Fecha Invalida</span>
          )}
          </Form.Field>
          <Form.Field >
            <Label className=""  color='black'>Telefono</Label>
            <Input
              type="text"
              name="telefono"
              placeholder="Telefono"
              icon="user circle outline"
              value={formData.telefono}
              error={formError.telefono}
              onChange={onChange}
            />
            {formError.direccion && (
              <span className="error-text">Debe Ingresar un Telefono</span>
            )}
          </Form.Field>
          </Form.Group>
          <Form.Field >
          <Label className=""  color='black'>Direccion</Label>
          <TextArea 
            placeholder='Direccionde Habitacion...' 
            name="direccion" 
            value={formData.direccion}
            error={formError.direccion}
            onChange={onChange}
            />
            {formError.direccion && (
              <span className="error-text">Debe Ingresar una Direccion</span>
            )}
          </Form.Field>
          
          <Form.Group widths='equal'>
          <Form.Field >
            <Label className=""  color='black'>Esta Bautizado</Label>
            <Select
              name="is_bautizado"
              options={bautiOpciones}
              value={formData.is_bautizado}
              error={formError.is_bautizado}
              onChange={onChange}
              
            />
            {formError.is_bautizado && (
              <span className="error-text">Erro de Dato</span>
            )}
          </Form.Field>
          <Form.Field >
            <Label className=""  color='black'>Es Servidor</Label>
            <Select
              name="is_servidor"
              options={bautiOpciones}
              placeholder="Es Servidor"
              value={formData.is_servidor}
              onChange={onChange}
              
            />
            {formError.is_servidor && (
              <span className="error-text">Erro de Dato</span>
            )}
          </Form.Field>
          <Form.Field >
            <Label className=""  color='black'>Territorio al que Pertenece</Label>
            <Select
              name="territorio_id"
              options={terriData}
              value={formData.territorio_id}
              error={formError.territorio_id}
              onChange={onChange}
              
              
            />
            {formError.territorio_id && (
              <span className="error-text">Error de Dato</span>
            )}
          </Form.Field>
          </Form.Group>
          <Form.Field >
          <Label className=""  color='black'>Peticion</Label>
          <TextArea 
            placeholder='Peticion...' 
            name="peticion" 
            value={formData.peticion}
            error={formError.peticion}
            onChange={onChange}
            />
            {formError.peticion && (
              <span className="error-text"></span>
            )}
          </Form.Field>
          <Button color='green' type="submit" name="submit" loading={isLoading} >
            Guardar
          </Button>
          <Button color='red' type='button' name="cancel" onClick={() => { setOpen(false) }} loading={isLoading} >
            Cancelar
          </Button>
        </Form>
      </>
    )
  }



  return (
    <>
      {handlerForm()}
    </>
  );
}

function defaultValueForm() {
  return {
    id: 0,
    cedula: "",
    nombre: "",
    fecha_nacimiento: new Date(),
    fecha_ingreso: new Date(),
    direccion: "",
    telefono: "",
    is_bautizado:"0",
    is_servidor:"0",
    territorio_id: 0,
    primer_evento_id: 0,
    peticion: "",
  };
}
