const axios = require("./Axios");

export const NuevasAlmas = function (fechaIni,fechaFin) {
    return Query(`comando=nuevas_almas&fecha_ini=${fechaIni}&fecha_fin=${fechaFin}`) 
};

export const NuevasAlmasTerritorios = function (fechaIni,fechaFin) {
  return Query(`comando=nuevas_almas_territorio&fecha_ini=${fechaIni}&fecha_fin=${fechaFin}`) 
};


export const Query = function (parametros) {
    return new Promise(function (resolve, reject) {
        axios.get(`/personas/query?${parametros}`)
        .then(results => {
          //console.log(results.data)
          if(results.data.result === 'ok') {
            var data = JSON.stringify(results.data);
            resolve(data);
          } else {
            reject(null);
          }
  
        }).catch(function (error) {
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            reject(null);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
            reject(null);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
            reject(null);
          }
      
        });
  
        
    });
  
    
  };

