import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import Auth from "../pages/Auth";
import Evento from "../pages/Evento";
import Consulta from "../pages/Consulta";
//import { ConsultaEvento } from "../models/Eventos";

export default function Rutas() {
  return (
      <Routes>
          <Route path='/' element={<Home/>} />
          <Route path='registrar' element={<Auth accion="register" />} />
          <Route path='evento-consulta' element={<Evento accion="consultar" />} />
          <Route path='evento-nuevo' element={<Evento accion="nuevo" />} />
          <Route path='evento-editar' element={<Evento accion="editar" />} >
            <Route path=":eventoId" element={<Evento accion="editar" />} />
          </Route>
          <Route path='evento-control' element={<Evento accion="control" />} >
            <Route path=":eventoId" element={<Evento accion="control" />} />
          </Route>
          <Route path='consulta-almas' element={<Consulta accion="nuevas-almas" />} />
      </Routes>

  );
}
