import React, { useState } from "react";
import { useParams } from "react-router-dom";
import ConsultaForm from "../../components/Evento/ConsultaForm";
import GuardarForm from "../../components/Evento/GuardarForm";
import ControlForm from "../../components/Evento/ControlForm";
import "./Evento.scss";

export default function Evento(props) {
  let params = useParams();
  const { setUser, accion } = props;
  const [selectedForm, setSelectedForm] = useState(accion);

  const handlerForm = () => {
    
    

    switch (accion) {
      case "nuevo":
        return <GuardarForm eventoId={0} setSelectedForm={setSelectedForm} setUser={setUser} />
      case "editar":
        return <GuardarForm eventoId={params.eventoId} setSelectedForm={setSelectedForm} setUser={setUser} />
      case "control":
        return <ControlForm eventoId={params.eventoId} setSelectedForm={setSelectedForm} setUser={setUser} />        
      case "consultar":
        return <ConsultaForm selectedForm={selectedForm} setSelectedForm={setSelectedForm} setUser={setUser} />
      default:
        return <ConsultaForm selectedForm={selectedForm} setSelectedForm={setSelectedForm} setUser={setUser} />
    }
  };

  
  return (
    
      <>
        {handlerForm()}
      </>
    
  );
  }