import React from "react";
import { Grid } from "semantic-ui-react";
import Rutas from "../../routes/Rutas";
//import TopBar from "../../components/TopBar";
import MenuBar from "../../components/MenuBar";
import FondoAuth from "../../assets/background-cuadrangular.png";
import "./LoggedLayout.scss";


export default function LoggedLayout(props) {
  const { setUser, user } = props;

  //const electron = window.require("electron");
  //const ipcRenderer = electron.ipcRenderer;

  
    return (
    
      <Grid className="logged-layout">
        <Grid.Row>
          <Grid.Column className="topmenu" width={16}>
            <MenuBar setUser={setUser} user={user} />
          </Grid.Column>
        </Grid.Row>
{/*        <Grid.Row>
          <Grid.Column className="topbar" width={16}>
            <TopBar />
          </Grid.Column>
        </Grid.Row>
*/}
        <Grid.Row>
          <Grid.Column className="content" width={16}>
            <div className="workspace" style={{ backgroundImage: `url(${FondoAuth})` }}>
            <div className="workspace__dark" />
            <div className="workspace__box" >
              <Rutas />
            </div>
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column className="statusbar" width={16}>
            <h2>Barra Inferior</h2>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    
  );
}
