//import logo from './logo.svg';
import './App.scss';
import React, { useState } from "react";
import Auth from "./pages/Auth";
import LoggedLayout from "./layouts/LoggedLayout";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CookiesProvider, useCookies } from 'react-cookie';



function App() {

  const axios = require("./models/Axios");  

  const [user, setUser] = useState(null);
  
  const [cookies, setCookie, removeCookie] = useCookies(['user']);

  

  if(!user) {
    if(cookies.user) {
      axios.setToken(cookies.user.token)
      setUser(cookies.user)
      console.log('Existe cookie...')
    } 

  } else {
    if(user==='logout') {
      setCookie('user', null)
      removeCookie('user')
      setUser(null);
      console.log('Saliendo...')
    } else {
      if(!cookies.user) {
        setCookie('user', user)
        console.log('Cargando cookie...')
      } 
    }
  }


  return (
    <>
      <CookiesProvider>
        <ToastContainer />
        {!user ? (
          <Auth setUser={setUser} />
        ) : (
          <LoggedLayout user={user} setUser={setUser} />
        )}
      </CookiesProvider>
    </>
  );
}

export default App;
