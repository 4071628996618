import React, { useState, useEffect, useRef } from "react";
import { Button, Grid, Segment, Label, Table, Modal } from "semantic-ui-react";
import { toast } from "react-toastify";
import "./ControlForm.scss";
import GuardarPersona from "../../Persona/GuardarPersona";
import BuscarPersona from "../../Persona/BuscarPersona";
import { useNavigate } from "react-router-dom";


import Moment from 'moment';

const Eventos = require("../../../models/Eventos");



export default function ControlForm(props) {

  const { eventoId } = props;

  const [formData, setFormData] = useState({});
  const [evNum, setEvNum] = useState(1);
  const [newCant, setNewCant] = useState(0);
  const [newAsist, setNewAsist] = useState(0);
  const [asisCant, setAsisCant] = useState(0);
  const [personaId, setPersonaId] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const navegar = useNavigate();
  const [open, setOpen] = useState(false)
  const [aopen, setAopen] = useState(false)

  const en = useRef(1);

  useEffect(() => {
    if(newAsist > 0) {

      let gaarg = { persona_id: newAsist, evento_id: eventoId }
      Eventos.GuardarAsistencia(gaarg).then(function (data) {
        en.current++
        console.log(`cuenta: ${en.current}`)
        setEvNum(en.current);
        toast.success("Asistencia agregada");
      }).catch(function (error) {
        toast.error("Ocurrio un Problema a Registrar Asistencia");
        console.log(error);
      });
      //setNewAsist(0)

    }
  }, [ newAsist, eventoId ] );

  useEffect(() => {
    let eId = parseInt(eventoId, 10)
    
    if(eId > 0) {
      setIsLoading(true);
      Eventos.Obtener(eId,1)
      .then(function (data) {
        setIsLoading(false);
        let dt = JSON.parse(data)
        if(dt.result==='ok') {
          dt.data.persona.reverse()
          setFormData(dt.data);
          setAsisCant(dt.data.persona.length);
          let nw = 0;
          let x = 0;
          for(x=0;x<dt.data.persona.length;x++) {
            if(parseInt(dt.data.persona[x].primer_evento_id) === parseInt(eventoId)) nw++;
          }
          setNewCant(nw);

        }
      })
      .catch(function (error) {
        setIsLoading(false);
        toast.error("Ocurrio un Problema");
        console.log(error);
        navegar("/evento-consulta");
      });
    } else {
        navegar("/evento-consulta");
    }
  }, [ eventoId, evNum, navegar ] );


  const nuevosIngresos = (pi) =>  {
    
  
    return (
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
      >
        <Modal.Header><Label color='blue' attached='top' size='huge'>Nueva Alma</Label></Modal.Header>
        <Modal.Content >         
              <GuardarPersona personaId={personaId} setOpen={setOpen} eventoId={eventoId} setEvNum={setEvNum} evNum={evNum}/>
        </Modal.Content>
        
      </Modal>
    )
  }


  


  const agregarAsistente = () =>  {
      return (
      <Modal
        onClose={() => setAopen(false)}
        onOpen={() => setAopen(true)}
        open={aopen}
      >
        <Modal.Header><Label color='blue' attached='top' size='huge'>Buscar</Label></Modal.Header>
        <Modal.Content >         
              <BuscarPersona setAopen={setAopen} eventoId={eventoId} setEvNum={setEvNum} evNum={evNum}/>
        </Modal.Content>
        
      </Modal>
    )
  }

  const NuevaAlma = (props) => {
    if (parseInt(props.primer_evento_id) != parseInt(eventoId)) return null  
    return <Button  circular icon='heart outline' />
  }

  
  const handlerForm = () => {
    return (

        <Segment loading={isLoading}>
        <Label attached='top' size='huge' color='blue' >Datos del Servicio</Label>
        <Grid className="acciones">
        <Grid.Row>
        <Grid.Column className="listado" width={4}>
          <Segment>
          <Label attached='top' size='large'>
            Fecha:
            </Label>
              <div>{Moment(formData.fecha).format('DD/MM/YYYY hh:mm a')}</div>
            </Segment>
          </Grid.Column>
          <Grid.Column className="listado" width={12}>
          <Segment>
            <Label attached='top' size='large'>
                Descripcion:
            </Label>
            <div>{formData.descripcion}</div>
            </Segment>

          </Grid.Column>
          <Grid.Column className="listado" width={4}>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column className="listado" width={16}>
              <Segment size='massive'>
              <Label attached='top' size='massive'>Asistentes
              
              <Button
                basic
                color='blue'
                content='Nuevas Almas'
                floated='right'
                icon='plus'
                onClick={() => {setPersonaId(0); setOpen(true)}}
                label={{
                  as: 'a',
                  basic: true,
                  color: 'black',
                  pointing: 'left',
                  content: newCant,
                }}
              />
              <Button
                basic
                color='black'
                content='Asistentes'
                icon='list ul'
                floated='right'
                label={{
                  as: 'a',
                  basic: true,
                  color: 'black',
                  pointing: 'left',
                  content: asisCant,
                }}
              />
              </Label>
              
              </Segment>
              <Segment widths='equal' >
                <BuscarPersona setNewAsist={setNewAsist}  />
              </Segment>
                <Table selectable striped>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell collapsing>Estatus</Table.HeaderCell>
                        <Table.HeaderCell collapsing>Cedula</Table.HeaderCell>
                        <Table.HeaderCell>Nombre</Table.HeaderCell>
                        <Table.HeaderCell width={2} >Acciones</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {formData.persona != null ? formData.persona.map(pers => (
                        <Table.Row key={pers.id}>
                          <Table.Cell><NuevaAlma primer_evento_id={pers.primer_evento_id} /> </Table.Cell>
                          <Table.Cell>{pers.cedula}</Table.Cell>
                          <Table.Cell>{pers.nombre}</Table.Cell>
                          <Table.Cell >
                            <Button  circular icon='edit' onClick={() => {setPersonaId(pers.id); console.log(`Manda Per: ${pers.id}`); setOpen(true); }}/>
                            <Button  circular icon='list ol' />
                          </Table.Cell>
                        </Table.Row>
                      )) : <></>}
                    </Table.Body>
                </Table>
                
          </Grid.Column>
        </Grid.Row>
        
      </Grid>

    </Segment>

    )
  }



  return (
    <>
      {handlerForm()}
      {nuevosIngresos()}
      {agregarAsistente()}
    </>
  );
}


