//const connection = require("./Connection.js").connection;
//import axios from "./Axios"

const axios = require("./Axios");


export const Login = function (arg) {
  return new Promise(function (resolve, reject) {

      axios.post('/users/login', {
        email    : arg.email,
        password : arg.password
      })
      .then(results => {

        var data = JSON.stringify(results.data);
        
        if(results.data.result === 'ok') {
          axios.setToken(results.data.data.token);
          //console.log(results.data.data.token);
          //console.log('Bearer: ' + results.data.token);
          resolve(data);
        } else {
          reject(null);
        }

      }).catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          reject(null);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
          reject(null);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
          reject(null);
        }
    
      });

      
  });
/*

  return new Promise(function (resolve, reject) {
    var data = "[]";
    console.log(arg);
    connection.query(
      "SELECT * FROM usuarios WHERE email = ? AND password = ?",
      [arg.email, arg.password],
      function (error, results) {
        if (!error) {
          console.log(results);
          data = JSON.stringify(results);
          resolve(data);
        } else {
          reject(error);
        }
      }
    );
  });
  */

};

export const Logout = function() {
  axios.setToken(null);
}

export const Register = function (arg) {
  return new Promise(function (resolve, reject) {
      axios.post('/users/register', {
        email    : arg.email,
        password : arg.password,
        cedula    : arg.cedula,
        nombre    : arg.nombre
      })
      .then(results => {
        var data = JSON.stringify(results.data);
        //console.log(data);
        if(results.data.result === 'ok') {
          resolve(data);
        } else {
          reject(null);
        }

      }).catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          reject(null);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
          reject(null);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
          reject(null);
        }
    
      });
  });
  
};
