import React, { useState, useEffect } from "react";
import { Table, Label, Segment, Button } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Moment from 'moment';

const Eventos = require("../../../models/Eventos");

export default function ConsultaForm(props) {
  //const { setSelectedForm } = props;
  const navegar = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [lstEvent, setLstEvent] = useState([]);
  const filtro = "";

  
  useEffect(() => {
    setIsLoading(true);
    Eventos.ConsultaEvento(filtro)
      .then(function (data) {
        setIsLoading(false);
        //console.log('Data Eventos: ' + data);
        var dt = JSON.parse(data);
        setLstEvent(dt.data);
      })
      .catch(function (error) {
        setIsLoading(false);
        toast.error("Ocurrio un Problema");
        console.log(error);
      });
  }, [ filtro ] );




  return (
    <>
    <Segment loading={isLoading}>
      <Label attached='top' size='huge'>Consulta de Eventos</Label>
      <Table >
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width="3" >Fecha</Table.HeaderCell>
          <Table.HeaderCell>Descripcion</Table.HeaderCell>
          <Table.HeaderCell width="3" >Accion</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
      {lstEvent.map(evento => (
          <Table.Row key={evento.id}>
            <Table.Cell>{Moment(evento.fecha).format('DD/MM/yyyy - hh:mm a')}</Table.Cell>
            <Table.Cell>{evento.descripcion}</Table.Cell>
            <Table.Cell >
              <Button  onClick={() => {navegar(`/evento-editar/${evento.id}`)}} circular icon='edit' />
              <Button  onClick={() => {navegar(`/evento-control/${evento.id}`)}} circular icon='list ol' />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>

      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan='3'>
          
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
      </Table>
      </Segment>
      
    </>
  );
}