//import React from 'react';
import ReactDOM from 'react-dom';

import './index.scss';
import App from './App';
import { BrowserRouter } from "react-router-dom";
//import reportWebVitals from './reportWebVitals';

//React.StrictMode
//</React.StrictMode>

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
    ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
