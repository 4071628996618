import axios from "axios"

const basePath = 'https://api.salvasion.com.ve';
//const basePath = 'http://127.0.0.1:3333';

class Token {
    static value = null;
}


export const setToken = function(xtok) {

    Token.value = xtok;
}


export const post = function(ruta,data) {
    var config = { headers: {} };
    if(Token.value !== null) {
        config = {
            headers: { Authorization: `Bearer ${Token.value}` }
        };
    }

    return axios.post(basePath + ruta, data, config);

}


export const get = function(ruta) {

    var config = { headers: {} };
    if(Token.value !== null) {
        config = {
            headers: { Authorization: `Bearer ${Token.value}` }
        };
    }

    return axios.get(basePath + ruta, config);

}
