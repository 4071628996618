const axios = require("./Axios");

export const Consulta = function (arg) {
  return new Promise(function (resolve, reject) {
      axios.get('/territorios/list')
      .then(results => {
        
        if(results.data.result === 'ok') {
          
          var data = JSON.stringify(results.data);
          resolve(data);
        } else {
          reject(null);
        }

      }).catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          reject(null);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
          reject(null);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
          reject(null);
        }
    
      });

      
  });

  
};

export const Guardar = function (arg) {
  return new Promise(function (resolve, reject) {
    console.log("Email: " + arg.email + "    Pass: arg.password");
      axios.post('/eventos/guardar', {
        id    : arg.id,
        fecha : arg.fecha,
        descripcion    : arg.descripcion
      })
      .then(results => {
        var data = JSON.stringify(results.data);
        //console.log(data);
        if(results.data.result === 'ok') {
          resolve(data);
        } else {
          reject(null);
        }

      }).catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          reject(null);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
          reject(null);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
          reject(null);
        }
    
      });
  });
  
};


export const Obtener = function (id,list) {
  return new Promise(function (resolve, reject) {
      axios.get(`/eventos/obtener?id=${id}&${list}=0`)
      .then(results => {
        if(results.data.result === 'ok') {
          var data = JSON.stringify(results.data);
          resolve(data);
        } else {
          reject(null);
        }
      }).catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          reject(null);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
          reject(null);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
          reject(null);
        }
      });
  });
};