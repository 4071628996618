import React from "react";
import { Dropdown, Menu } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";

const Usuarios = require("../../models/Usuarios");


export default function MenuBar(props) {
  const { setUser, user } = props;
  const navegar = useNavigate();
  var xroles = '';

  if(user.roles !== undefined) xroles = user.roles;

  let lstPerm = xroles.split(',');

  function logoutUser() {
    Usuarios.Logout();
    setUser('logout');
    //navegar("/");
    //console.log('Saliendo...')
  };

  const veriPermi = (rol) => {
    if(lstPerm.findIndex(idx => idx === 'admin') >= 0) return true;

    return (lstPerm.findIndex(idx => idx === rol) >= 0)
  }

  const registrar = () => {
    navegar("/registrar");
  };

  const consultaEvento = () => {
    navegar("/evento-consulta");
  };

  const nuevoEvento = () => {
    navegar("/evento-nuevo");
  };

  const consultaNuevasAlmas = () => {
    navegar("/consulta-almas");
  };

  return (
    <>
      <Menu inverted >
        <Dropdown item text="Usuarios" >
          <Dropdown.Menu>
            <Dropdown.Item  icon="user secret" text="Cambiar Clave"/>
            {veriPermi('editusuario') ? (<Dropdown.Item  icon="user plus" onClick={registrar} text="Registrar Usuario" />) : (<></>)}
            <Dropdown.Divider />
            <Dropdown.Item icon="window close" onClick={logoutUser} text="Salir" />
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown item text="Servicios" >
          <Dropdown.Menu>
            {veriPermi('editevento') ? (<Dropdown.Item icon="add to calendar" onClick={nuevoEvento} text="Nuevo" />) : (<></>)}
            {veriPermi('verevento') ? (<Dropdown.Item icon="calendar alternate" onClick={consultaEvento} text="Consultar" />) : (<></>)}
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown item text="Consultas" >
          <Dropdown.Menu>
          {veriPermi('conspersonas') ? (<Dropdown.Item icon="calendar alternate" onClick={consultaNuevasAlmas} text="Nuevas Almas" />) : (<></>)}
          </Dropdown.Menu>
        </Dropdown>
      </Menu>
    </>
  );
}
