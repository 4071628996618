import React, { useState } from "react";
import { Button, Icon, Form, Input } from "semantic-ui-react";
import { toast } from "react-toastify";
import { validateEmail } from "../../../utils/Validations";
import "./LoginForm.scss";

const Usuarios = require("../../../models/Usuarios");

export default function LoginForm(props) {
  const { setUser } = props;

  const [formData, setFormData] = useState(defaultValueForm());
  const [showPass, setShowPass] = useState(false);
  const [formError, setFormError] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  //const electron = window.require("electron");
  //const ipcRenderer = electron.ipcRenderer;

  const handlerShowPass = () => {
    setShowPass(!showPass);
  };

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = () => {
    setFormError({});
    let errors = {};
    let formOk = true;
  

    if (!validateEmail(formData.email)) {
      errors.email = true;
      formOk = false;
    }

    if (formData.password.length < 6) {
      errors.password = true;
      formOk = false;
    }

    setFormError(errors);

    if (formOk) {
      setIsLoading(true);
      Usuarios.Login(formData)
        .then(function (data) {
          setIsLoading(false);
          var usr = JSON.parse(data);
          if (usr.result === "ok") {
            setUser(usr.data);
            //console.log(usr.data);
            toast.success(`Bienvenido(a) ${usr.data.nombre} !!! `);
          } else {
            toast.error("Usuario y/o Clave Invalida");
          }
          
        })
        .catch(function (error) {
          setIsLoading(false);
          toast.error("Algo va muy Mal");
          
        });
        
    }
  };

  return (
    <div className="login-form">
      <Form onSubmit={onSubmit} onChange={onChange}>
        <Form.Field>
          <Input
            type="text"
            name="email"
            placeholder="Correo electronico"
            icon="mail outline"
            //onChange={}
            error={formError.email}
          />
          {formError.email && (
            <span className="error-text">Email invalido</span>
          )}
        </Form.Field>
        <Form.Field>
          <Input
            type={showPass ? "text" : "password"}
            name="password"
            placeholder="Contraseña"
            icon={
              showPass ? (
                <Icon name="eye slash outline" link onClick={handlerShowPass} />
              ) : (
                <Icon name="eye" link onClick={handlerShowPass} />
              )
            }
            //onChange={}
            error={formError.password}
          />
          {formError.password && (
            <span className="error-text">
              La clave debe tener al menos 6 digitos
            </span>
          )}
        </Form.Field>

        <Button type="submit" name="submit" loading={isLoading}>
          Entrar
        </Button>
      </Form>
    </div>
  );
}

function defaultValueForm() {
  return {
    email: "",
    password: "",
  };
}
