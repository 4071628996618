import React, { useState } from "react";
//import AuthOptions from "../../components/Auth/AuthOptions";
import RegisterForm from "../../components/Auth/RegisterForm";
import LoginForm from "../../components/Auth/LoginForm";
import FondoAuth from "../../assets/background-cuadrangular.png";
import LogoApp from "../../assets/logo-cuadrangular.png";
import "./Auth.scss";


export default function Auth(props) {
  const { setUser, accion } = props;
  const [selectedForm, setSelectedForm] = useState(accion);

  const handlerForm = () => {
    switch (selectedForm) {
      case "login":
        return (
          //<LoginForm setSelectedForm={setSelectedForm} setUser={setUser} />
          <h1> Login </h1>
        );
      case "register":
        return <RegisterForm setSelectedForm={setSelectedForm} />;
        //return <h1> registro</h1>
      default:
        //return <AuthOptions setSelectedForm={setSelectedForm} />;
        return <LoginForm setSelectedForm={setSelectedForm} setUser={setUser} />
        //return <h1> opciones </h1>
    }
  };


  return (
    <div className="auth" style={{ backgroundImage: `url(${FondoAuth})` }}>
      <div className="auth__dark" />
      <div className="auth__box">
        <div className="auth__box-logo">
          <img src={LogoApp} alt="Cuadrangular" />
        </div>
        {handlerForm()}
      </div>
    </div>
  );
}
